import React from 'react'
import logo from '../assets/images/logo.svg';

const HeaderTwo = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Learning Portal</h1>
        <p>
            <br />A goto place for learning or upskilling youself. We have both async training model and trainer led sync model.<br />
            <br /> For each curisoity that the studnets have there will be a track that will be created. We will start with Drupal, Python Django, Scurity and AWS. These tracks will run for 6 months. 3 months of exhaustive training followed by 3 months of assignments. During the training students pay 33k each every month. If they learn well then companies will shortlist them for intership and pay them a minimu of 15k and maximum of 33k per month.
            <br />We will also have smaller duration chorots like Crypto and Sketch which will last for couple of weeks. For these cohorts we might charge 10k to 20k<br />
            <br />Cohorts will be formed when enough number of stuents are ready. Each cohort will be headed by one chief mentor and other supporig mentors. We will also have many passive mentors who will answer the questions.<br />
            <br /> People who can't afford the higher fees can pay 5k to get access to slack channel so that they can learn on their own. If you are a student who can't afford even that tweet to me and will give you scholarships so that you can even learn free of cost.<br />
            <br />For more details reach out to  <a href="https://twitter.com/gokulnk" target="_blank">@gokulnk</a>.<br />
        </p>
    </header>
)

export default HeaderTwo
