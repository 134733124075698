import React from 'react'
import logo from '../assets/images/Learning_Paths_Logo.png';

const LearningPathsTools = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>LEARNING PATHS</h1>
        <p>Suite of Tools for connected thoughts. Gives you super powers. <br /><br />Let the structure emerge from Chaos.<br />

        </p>
        <hr />
        {/* <span className="logo"><img src={logo} alt="" /></span> */}
        <h1>Highlighter</h1>
        <p>Highlight, take notes and add tags across the web. <br /><br />Never forget anything important ever again. Reading online was never this fun before.<br />
            <footer className="major">
                <ul className="actions">
                    <li>
                        <a href="https://alpha.app.learningpaths.io/#/register" className="button" target="_blank">
                            Get Started
                        </a>
                    </li>
                    <li>
                        <a href="https://alpha.app.learningpaths.io/#/register" target="_blank" className="button">
                            Learn More
                        </a>
                    </li>
                </ul>
            </footer>
        </p>
        <hr />
        {/* <span className="logo"><img src={logo} alt="" /></span> */}
        <h1>Learning Portal</h1>
        <p>A goto place for learning or upskilling youself. A combination of async training model and trainer led sync model.<br /><br />Cohorts will be formed when enough number of stuents are ready. <br /><br />Each cohort will be headed by one chief mentor and other supporting mentors<br />
            <footer className="major">
                <ul className="actions">
                    <li>
                        <a href="https://discord.gg/Qzf8ufwbbk" className="button" target="_blank">
                            Start Learning Now
                        </a>
                    </li>
                </ul>
            </footer>
        </p>
    </header>
)

export default LearningPathsTools
